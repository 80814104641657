#Programs {
  padding: 60px 0;
}

#Programs .section-title {
  margin-bottom: 45px;
  text-align: center;
}

#Programs h2 {
  font-size: 30px;
  text-transform: uppercase;
  color: #333;
}

#Programs p {
  font-size: 16px;
  color: #555;
}

#Programs .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#Programs .col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0 15px;
  box-sizing: border-box;
}

#Programs .program-desc {
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.324);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

#Programs .program-desc:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

#Programs h3 {
  font-size: 20px;
  color: #333;
  margin: 0 0 10px; /* Üst ve alt marj ayarı. */
  text-transform: capitalize;
}

#Programs .program-desc p {
  font-size: 14px;
  color: #fff;
}

@media (max-width: 767px) {
  #Programs .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  #Programs h2 {
    font-size: 24px;
  }

  #Programs .program-desc p {
    font-size: 12px;
  }
}

