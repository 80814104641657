/*src > modals > PoliciesModal.css */
.content {
  margin-top: 20px;
}

.link-button {
  background: none;
  color: #fd6060;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  overflow-y: auto;
  padding: 20px;
}

.modal-container {
  background-color: #fff;
  padding: 20px;
  margin: auto;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  border: none;
  background: none;
  cursor: pointer;
}

.modal-title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

.question {
  color: #333;
}

.registration-form {
  display: flex;
  flex-direction: column;
}

.MuiTextField-root {
  margin-top: 8px;
  margin-bottom: 8px;
}

.MuiFormGroup-root {
  margin-top: 10px;
}

.MuiFormControlLabel-root {
  margin-right: 0;
}

.apply-button {
  background-color: #8b0000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 5px;
}

.apply-button:hover {
  background-color: #640000;
}

.toast {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  opacity: 0.9;
  z-index: 1500;
  animation: fadeOut 5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 0.9;
  }
  to {
    opacity: 0;
  }
}
