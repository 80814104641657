.staff-img-container {
    position: relative;
    display: inline-block;
  }

.staff-img{
    width: 100%;
    height: 100%;
    object-fit: fit;
  }
  
  .info-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .info-button:hover {
    background-color: #333;
  }
  