.register-btn {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.register-btn:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

/* Optional: Add entrance animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.register-btn {
  animation: fadeInUp 0.8s ease-out;
}
