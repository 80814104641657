@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

#about .inspirational-quote h1 {
  font-family: "Montserrat", sans-serif; /* Modern, attention-grabbing font */
  color: #333; /* Font color */
  font-size: 48px; /* Large, readable font size */
  text-transform: uppercase; /* UPPERCASE styling for emphasis */
  text-align: center; /* Centered text */
  margin: 40px 0; /* Adequate spacing above and below */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle text shadow for depth */
}

/* Ensure responsiveness */
@media (max-width: 767px) {
  #about .inspirational-quote h1 {
    font-size: 32px; /* Smaller font size on smaller screens */
  }
}
