@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #header,
  #about,
  #programs,
  #testimonials,
  #staff,
  #contact,
  #schedule,
  #gallery,
  #footer,
  #gallery{
    width: 111%;
  }
}
